import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const BaseButton = styled.button`
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  background-color: #007bff;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
`;

const PrimaryButtonStyled = styled(BaseButton)`
  background-color: #007bff;
  color: white;

  &:hover:not(:disabled) {
    background-color: #0056b3;
  }
`;

const SecondaryButtonStyled = styled(BaseButton)`
  background-color: #6c757d;
  color: white;

  &:hover:not(:disabled) {
    background-color: #5a6268;
  }
`;

const SuccessButtonStyled = styled(BaseButton)`
  background-color: #28a745;
  color: white;

  &:hover:not(:disabled) {
    background-color: #218838;
  }
`;

const ViewToggleButtonStyled = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;

  &:hover:not(:disabled) {
    background-color: ${(props) => props.theme.colors.primaryDark};
  }
`;

export const PrimaryButton = ({ children, ...props }) => {
  return <PrimaryButtonStyled {...props}>{children}</PrimaryButtonStyled>;
};

export const SecondaryButton = ({ children, ...props }) => {
  return <SecondaryButtonStyled {...props}>{children}</SecondaryButtonStyled>;
};

export const SuccessButton = ({ children, ...props }) => {
  return <SuccessButtonStyled {...props}>{children}</SuccessButtonStyled>;
};

export const LinkButton = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

export const ViewToggleButton = ({ children, ...props }) => {
  return <ViewToggleButtonStyled {...props}>{children}</ViewToggleButtonStyled>;
};
