import translations from "../translations/translations.js";

export function formatSourceProductInfo(
  sourceProduct,
  language,
  children = [],
  showPriceRanges = false
) {
  // Use the provided language if it exists in translations, otherwise use the default
  const t = translations.sourceProduct;

  const getCategories = () => {
    return (
      [
        sourceProduct.category1,
        sourceProduct.category2,
        sourceProduct.category3,
      ]
        .filter(Boolean)
        .join(" > ") || t.uncategorized[language.split("_")[0]]
    );
  };

  const getPriceRange = (prices) => {
    if (!prices || prices.length === 0) return null;
    const validPrices = prices.filter((p) => p !== null && p !== undefined);
    if (validPrices.length === 0) return null;

    const min = Math.min(...validPrices);
    const max = Math.max(...validPrices);

    if (min === max) {
      return `${min.toFixed(2)} € HT`;
    }
    return `${min.toFixed(2)} € HT - ${max.toFixed(2)} € HT`;
  };

  const langShort = language.split("_")[0];

  if (showPriceRanges) {
    const childrenPrices =
      children?.map((child) => ({
        buyPrice: child.buyPrice,
        wholesalerPrice: child.wholesalerPrice,
        retailerPrice: child.retailerPrice,
        publicPrice: child.publicPrice,
      })) || [];

    const buyPriceRange = getPriceRange(childrenPrices.map((p) => p.buyPrice));
    const wholesalerPriceRange = getPriceRange(
      childrenPrices.map((p) => p.wholesalerPrice)
    );
    const retailerPriceRange = getPriceRange(
      childrenPrices.map((p) => p.retailerPrice)
    );
    const publicPriceRange = getPriceRange(
      childrenPrices.map((p) => p.publicPrice)
    );

    return {
      [t.id[langShort]]: sourceProduct.sourceProductId,
      [t.sourceName[langShort]]: sourceProduct.name,
      [t.provider[
        langShort
      ]]: `${sourceProduct.provider}-${sourceProduct.providerRef}`,
      [t.categories[langShort]]: getCategories(),
      [t.stock[langShort]]: sourceProduct.totalStock,
      [t.mainWarehouse[langShort]]: sourceProduct.mainWarehouse
        ? `${sourceProduct.mainWarehouseStock || 0} (${
            sourceProduct.mainWarehouse
          })`
        : t.notAvailable[langShort],
      [t.customizable[langShort]]: sourceProduct.customizable
        ? t.yes[langShort]
        : t.no[langShort],
      [t.buyPrice[langShort]]:
        buyPriceRange || `${sourceProduct.buyPrice?.toFixed(2) || "0.00"} € HT`,
      [t.wholesalerPrice[langShort]]:
        wholesalerPriceRange ||
        `${sourceProduct.wholesalerPrice?.toFixed(2) || "0.00"} € HT`,
      [t.retailerPrice[langShort]]:
        retailerPriceRange ||
        `${sourceProduct.retailerPrice?.toFixed(2) || "0.00"} € HT`,
      [t.publicPrice[langShort]]:
        publicPriceRange || `${sourceProduct.publicPrice.toFixed(2)} € HT`,
    };
  }

  return {
    [t.id[langShort]]: sourceProduct.sourceProductId,
    [t.sourceName[langShort]]: sourceProduct.name,
    [t.provider[
      langShort
    ]]: `${sourceProduct.provider}-${sourceProduct.providerRef}`,
    [t.categories[langShort]]: getCategories(),
    [t.stock[langShort]]: sourceProduct.totalStock,
    [t.mainWarehouse[langShort]]: sourceProduct.mainWarehouse
      ? `${sourceProduct.mainWarehouseStock || 0} (${
          sourceProduct.mainWarehouse
        })`
      : t.notAvailable[langShort],
    [t.customizable[langShort]]: sourceProduct.customizable
      ? t.yes[langShort]
      : t.no[langShort],
    [t.buyPrice[langShort]]: `${
      sourceProduct.buyPrice?.toFixed(2) || "0.00"
    } € HT`,
    [t.wholesalerPrice[langShort]]: `${
      sourceProduct.wholesalerPrice?.toFixed(2) || "0.00"
    } € HT`,
    [t.retailerPrice[langShort]]: `${
      sourceProduct.retailerPrice?.toFixed(2) || "0.00"
    } € HT`,
    [t.publicPrice[langShort]]: `${sourceProduct.publicPrice.toFixed(2)} € HT`,
  };
}

export function getDescriptionField(descriptions, fieldName, locale) {
  if (!descriptions) {
    return null;
  }

  if (!locale) {
    locale = "fr_FR";
  }

  const desc = descriptions.find(
    (description) =>
      description.locale === locale && description.src === "laddd-bb"
  );

  if (desc[fieldName] === undefined) {
    throw new Error(`Field ${fieldName} not found in descriptions`);
  }

  return desc[fieldName];
}
