import React, { useEffect, useRef } from "react";
import {
  PrimaryButton,
  SecondaryButton,
  ViewToggleButton,
} from "../ui/Buttons";
import { useTranslation } from "../../hooks/useTranslation";
import {
  FilterContainer,
  FilterForm,
  FilterInput,
  FilterSelect,
  ButtonContainer,
} from "./SourceProductFilter.styles";

const filterDefinitions = [
  { name: "sourceProductIds", type: "array", placeholder: "sourceProductId" },
  {
    name: "providers",
    type: "array",
    placeholder: "providers",
    options: ["rbz", "bcn"],
  },
  { name: "providerRefs", type: "array", placeholder: "providerRef" },
  { name: "name", type: "string", placeholder: "name" },
  { name: "categories1", type: "array", placeholder: "category1" },
  { name: "categories2", type: "array", placeholder: "category2" },
  { name: "minTotalStock", type: "int", placeholder: "minTotalStock" },
  { name: "maxTotalStock", type: "int", placeholder: "maxTotalStock" },
  {
    name: "audiences",
    type: "array",
    placeholder: "audiences",
    options: ["homme", "femme", "enfant", "bébé"],
  },
];

function SourceProductFilter({
  filters,
  setFilters,
  applyFilters,
  viewMode,
  onViewModeToggle,
}) {
  const { t } = useTranslation();
  const initialLoadDone = useRef(false);

  useEffect(() => {
    if (!initialLoadDone.current) {
      // Load filters from local storage on component mount
      const savedFilters = localStorage.getItem("sourceProductFilters");
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        setFilters(parsedFilters);
        applyFilters(parsedFilters); // Apply the loaded filters
      } else {
        applyFilters(filters); // Apply default filters if no saved filters
      }
      initialLoadDone.current = true;
    }
  }, [setFilters, applyFilters, filters]);

  useEffect(() => {
    // Save filters to local storage whenever they change, but not on initial load
    if (initialLoadDone.current) {
      localStorage.setItem("sourceProductFilters", JSON.stringify(filters));
    }
  }, [filters]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const filterDef = filterDefinitions.find((def) => def.name === name);
    setFilters((prev) => {
      if (value === "") {
        return { ...prev, [name]: undefined };
      }

      if (filterDef.type === "array") {
        const arrayValue = value
          .split(",")
          .filter((item) => item !== "" && item != null);
        return {
          ...prev,
          [name]: arrayValue.length > 0 ? arrayValue : undefined,
        };
      } else if (filterDef.type === "int") {
        const parsedValue = parseInt(value, 10);
        return {
          ...prev,
          [name]: isNaN(parsedValue) ? undefined : parsedValue,
        };
      } else if (filterDef.type === "float") {
        const parsedValue = parseFloat(value);
        return {
          ...prev,
          [name]: isNaN(parsedValue) ? undefined : parsedValue,
        };
      } else {
        return { ...prev, [name]: value || undefined };
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    applyFilters(filters); // Pass the current filters to applyFilters
  };

  const handleClearFilters = () => {
    const clearedFilters = {};
    filterDefinitions.forEach((filterDef) => {
      clearedFilters[filterDef.name] = undefined;
    });
    setFilters(clearedFilters);
    applyFilters(clearedFilters);
    localStorage.removeItem("sourceProductFilters");
  };

  return (
    <FilterContainer>
      <FilterForm onSubmit={handleSubmit}>
        {filterDefinitions.map((filterDef) =>
          filterDef.type === "array" && filterDef.options ? (
            <FilterSelect
              key={filterDef.name}
              name={filterDef.name}
              value={filters[filterDef.name] || []}
              onChange={(e) => {
                const selectedOptions = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                setFilters((prev) => ({
                  ...prev,
                  [filterDef.name]:
                    selectedOptions.length > 0 ? selectedOptions : undefined,
                }));
              }}
              multiple
              size="2"
            >
              {filterDef.options.map((option) => (
                <option key={option} value={option}>
                  {t("productFilter", option, option)}
                </option>
              ))}
            </FilterSelect>
          ) : (
            <FilterInput
              key={filterDef.name}
              type={
                filterDef.type === "int" || filterDef.type === "float"
                  ? "number"
                  : "text"
              }
              name={filterDef.name}
              placeholder={t("productFilter", filterDef.placeholder)}
              value={
                filterDef.type === "array"
                  ? filters[filterDef.name]
                    ? filters[filterDef.name].join(",")
                    : ""
                  : filters[filterDef.name] || ""
              }
              onChange={handleChange}
            />
          )
        )}

        <ButtonContainer>
          <div>
            <ViewToggleButton type="button" onClick={onViewModeToggle}>
              {viewMode === "grid"
                ? t("buttons", "showTable")
                : t("buttons", "showGrid")}
            </ViewToggleButton>
          </div>
          <div>
            <PrimaryButton type="submit">
              {t("buttons", "applyFilters")}
            </PrimaryButton>
            <SecondaryButton type="button" onClick={handleClearFilters}>
              {t("buttons", "clearFilters")}
            </SecondaryButton>
          </div>
        </ButtonContainer>
      </FilterForm>
    </FilterContainer>
  );
}

export default SourceProductFilter;
