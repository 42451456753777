import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { postStripeCheckoutSessionCreateApiUrl } from "../../config/apiConfig";
import useCart from "../../hooks/useCart"; // Import the useCart hook

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const StripeCheckoutForm = () => {
  const {
    getCartItems,
    getCartTotal,
    getCartTotalIncTax,
    isLoading,
    isInitialized,
  } = useCart(); // Use the useCart hook
  const [clientSecret] = useState(null);

  useEffect(() => {
    const fetchClientSecret = async () => {
      // Only proceed if the cart is initialized and not loading
      if (!isInitialized || isLoading) {
        return;
      }

      const cartItems = getCartItems();
      const totalAmount = getCartTotal();
      const totalAmountIncTax = getCartTotalIncTax();

      const req = {
        lineItems: cartItems.map((item) => ({
          sku: item.sku,
          name: item.name,
          vatRate: item.vatRate,
          quantity: item.quantity,
          unitPrice: Math.round(item.price * 100),
          unitPriceIncTax: Math.round(
            item.unitPrice * (1 + item.vatRate / 1000) * 100
          ),
          price: Math.round(item.price * item.quantity * 100),
          priceIncTax: Math.round(
            item.price * (1 + item.vatRate / 1000) * item.quantity * 100
          ),
        })),
        currency: "EUR",
        totalAmount: Math.round(totalAmount * 100),
        totalAmountIncTax: Math.round(totalAmountIncTax * 100),
        returnUrl: process.env.REACT_APP_URL,
        successUrl: process.env.REACT_APP_URL,
      };

      try {
        const response = await axios.post(
          postStripeCheckoutSessionCreateApiUrl,
          req
        );
        const redirectToUrl = response.data.stripeCheckoutUrl;

        // Wait 3 seconds before redirecting
        setTimeout(() => {
          window.location.href = redirectToUrl;
        }, 3000);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    fetchClientSecret();
  }, [
    isInitialized,
    isLoading,
    getCartItems,
    getCartTotal,
    getCartTotalIncTax,
  ]);

  if (!isInitialized || isLoading) {
    return <div>Loading cart...</div>;
  }

  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  const options = {
    clientSecret,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
