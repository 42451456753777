import React from "react";
import styled from "styled-components";
import PageHeader from "./PageHeader";
import StickyWrapper from "./StickyWrapper";
import { StickyProvider } from "../../contexts/StickyContext";

const MainContent = styled.main`
  background-color: ${(props) =>
    props.variant === "login" ? "#d5d5d5" : "transparent"};
  min-height: 100vh;
`;

function Layout({ children, title, breadcrumb, metaDescription, variant }) {
  return (
    <StickyProvider>
      {variant !== "login" && (
        <StickyWrapper stickyPos={0}>
          <PageHeader
            title={title}
            breadcrumb={breadcrumb}
            metaDescription={metaDescription}
          />
        </StickyWrapper>
      )}
      <MainContent variant={variant}>{children}</MainContent>
    </StickyProvider>
  );
}

export default Layout;
