import React, { useEffect } from "react";
import styled from "styled-components";
import { useLanguage } from "../../contexts/LanguageContext";
import { languageOptions } from "../../config/languages";

const Select = styled.select`
  padding: 5px;
  margin-left: 10px;
`;

const LanguageSelector = () => {
  const { language, setLanguage } = useLanguage();

  useEffect(() => {
    // Load language from localStorage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, [setLanguage]);

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    // Save language to localStorage
    localStorage.setItem("language", newLanguage);
  };

  return (
    <Select value={language} onChange={handleLanguageChange}>
      {languageOptions.map((option) => (
        <option key={option.code} value={option.code}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
