import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useSticky } from "../../contexts/StickyContext";

const StickyContainer = styled.div`
  position: sticky;
  top: ${(props) => props.$top}px;
  z-index: ${(props) => props.$zIndex};
`;

const StickyContent = styled.div`
  background-color: white;
  transition: all 0.3s ease;

  &.is-sticky {
    box-shadow: ${(props) =>
      props.$showShadow ? "0 4px 4px rgba(0, 0, 0, 0.1)" : "none"};
  }
`;

function StickyWrapper({ children, zIndex = 1000, stickyPos = 0 }) {
  const { stickyElements, lastStickyId, registerSticky, updateStickyHeight } =
    useSticky();
  const [id] = useState(() => Math.random().toString(36).substr(2, 9));
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef(null);
  const stickyStartRef = useRef(null);

  useEffect(() => {
    const unregister = registerSticky(
      id,
      ref.current?.offsetHeight || 0,
      stickyPos
    );
    return unregister;
  }, [id, registerSticky, stickyPos]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        updateStickyHeight(id, entry.contentRect.height);
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [id, updateStickyHeight]);

  useEffect(() => {
    const calculateStickyStart = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        stickyStartRef.current = rect.top + window.pageYOffset;
      }
    };

    calculateStickyStart();
    window.addEventListener("resize", calculateStickyStart);

    const handleScroll = () => {
      if (stickyStartRef.current !== null) {
        const scrollY = window.pageYOffset;
        const newIsSticky = scrollY > stickyStartRef.current - stickyPos;
        setIsSticky(newIsSticky);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", calculateStickyStart);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stickyPos]);

  const top = stickyElements
    .filter((el) => el.id !== id && el.stickyPos < stickyPos)
    .reduce((acc, el) => acc + el.height, 0);

  const showShadow = isSticky && id === lastStickyId;

  return (
    <StickyContainer $top={top} $zIndex={zIndex}>
      <StickyContent
        ref={ref}
        className={isSticky ? "is-sticky" : ""}
        $showShadow={showShadow}
      >
        {children}
      </StickyContent>
    </StickyContainer>
  );
}

export default StickyWrapper;
