import styled from "styled-components";

export const ContentContainer = styled.div`
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const Thead = styled.thead`
  background-color: ${({ theme }) => theme.table.header.backgroundColor};
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  &:hover {
    background-color: ${({ theme }) => theme.table.hover.backgroundColor};
  }
`;

export const Th = styled.th`
  padding: 1rem;
  text-align: left;
  border-bottom: 2px solid ${({ theme }) => theme.table.borders.color};
  color: ${({ theme }) => theme.text.primary};
`;

export const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.table.borders.color};
`;

export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.text.secondary};
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
`;
