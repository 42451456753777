import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaShoppingCart,
  FaSignInAlt,
  FaSignOutAlt,
  FaUser,
} from "react-icons/fa";
import { useCartContext } from "../../contexts/CartContext";
import { useTranslation } from "../../hooks/useTranslation";
import { MenuNav, CartIcon, CartCount } from "./Menu.styles";
import { useAuth } from "../../contexts/AuthContext";
import LanguageSelector from "./LanguageSelector";

function Menu() {
  const { t } = useTranslation();
  const { cart } = useCartContext();
  const { isAuthenticated, logout, user } = useAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);

  useEffect(() => {
    console.log("Cart updated:", cart);
  }, [cart]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showUserMenu && !event.target.closest(".user-menu-container")) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showUserMenu]);

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  const handleLogout = () => {
    logout();
    // Optionally navigate to login or home page
  };

  return (
    <MenuNav>
      <ul>
        <li>
          <Link to="/source-products">{t("menu", "products")}</Link>
        </li>
        <li>
          <LanguageSelector />
        </li>
        <li>
          <CartIcon to="/cart" title={t("menu", "cart")}>
            <FaShoppingCart size={25} />
            {cartItemCount > 0 && <CartCount>{cartItemCount}</CartCount>}
          </CartIcon>
        </li>
        <li className="user-menu-container">
          {isAuthenticated ? (
            <>
              <button
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="user-icon-button"
                title={t("menu", "account")}
              >
                <FaUser size={25} />
              </button>
              {showUserMenu && (
                <div className="user-submenu">
                  <div className="user-email">{user?.email}</div>
                  <button onClick={handleLogout}>
                    <FaSignOutAlt size={20} />
                    {t("menu", "logout")}
                  </button>
                </div>
              )}
            </>
          ) : (
            <Link to="/login">
              <FaSignInAlt size={25} />
            </Link>
          )}
        </li>
      </ul>
    </MenuNav>
  );
}

export default Menu;
