import styled from "styled-components";
import { LinkButton } from "../ui/Buttons";

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  padding: 1rem 0;
`;

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
  cursor: pointer;
`;

export const ProductDescription = styled.p`
  margin-top: 1rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CenteredLinkButton = styled(LinkButton)`
  display: block;
  margin: 1rem auto 0;
  text-align: center;
`;

export const ProductName = styled.h2`
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  display: block;
  height: 2rem;
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text.secondary};

  div {
    display: flex;
    justify-content: space-between;
  }
`;

export const StockInfo = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 0.5rem;
`;
