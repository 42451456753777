import { useState, useEffect, useCallback } from "react";

const useCart = () => {
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  const loadCart = useCallback(async () => {
    setIsLoading(true);
    try {
      const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
      setCart(storedCart);
    } catch (error) {
      console.error("Error loading cart:", error);
    } finally {
      setIsLoading(false);
      setIsInitialized(true);
    }
  }, []);

  useEffect(() => {
    loadCart();
  }, [loadCart]);

  const saveCart = (newCart) => {
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const updateQuantity = (item, newQuantity) => {
    const existingItemIndex = cart.findIndex(
      (cartItem) => cartItem.id === item.id && cartItem.size === item.size
    );

    if (existingItemIndex !== -1) {
      if (newQuantity === 0) {
        removeFromCart(item.id, item.size);
      } else {
        const newCart = [...cart];
        newCart[existingItemIndex].quantity = newQuantity;
        saveCart(newCart);
      }
    } else {
      if (newQuantity > 0) {
        saveCart([...cart, { ...item, quantity: newQuantity }]);
      }
    }
  };

  const addQuantity = (item, quantityToAdd = 1) => {
    const existingItemIndex = cart.findIndex(
      (cartItem) => cartItem.id === item.id && cartItem.size === item.size
    );

    if (existingItemIndex !== -1) {
      const newCart = [...cart];
      newCart[existingItemIndex].quantity += quantityToAdd;
      saveCart(newCart);
    } else {
      saveCart([...cart, { ...item, quantity: quantityToAdd }]);
    }
  };

  const removeFromCart = (id, size) => {
    const newCart = cart.filter(
      (item) => !(item.id === id && item.size === size)
    );
    saveCart(newCart);
  };

  const clearCart = () => {
    saveCart([]);
  };

  const getCartItems = () => {
    return cart.map((item) => ({
      id: item.id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      size: item.size,
    }));
  };

  const getCartTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getCartTotalIncTax = () => {
    return cart.reduce(
      (total, item) => total + item.priceIncTax * item.quantity,
      0
    );
  };

  return {
    cart,
    isLoading,
    isInitialized,
    updateQuantity,
    addQuantity,
    removeFromCart,
    clearCart,
    getCartItems,
    getCartTotal,
    getCartTotalIncTax,
    loadCart,
  };
};

export default useCart;
