import styled from "styled-components";

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 4px;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  color: red;
  padding: 20px;
  text-align: center;
`;

export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
`;

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 15px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const ImageColumn = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const InfoColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

export const AttributesColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

export const ProductDescription = styled.p`
  margin-top: 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #666;
`;

export const AddToCartButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: #0056b3;
  }
`;
