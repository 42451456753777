import styled from "styled-components";

export const FilterContainer = styled.div`
  background-color: #f0f0f0;
  padding: 1rem 2rem;
`;

export const FilterForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
`;

export const FilterInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  min-width: 80px;
`;

export const FilterSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  min-width: 80px;
  height: 4.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    display: flex;
    gap: 1rem;
  }
`;
