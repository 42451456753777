import React from "react";
import { useQuery } from "react-query";
import Layout from "../components/layout/Layout";
import { useTranslation } from "../hooks/useTranslation";
import { fetchUsers } from "../lib/userApi";
import {
  ContentContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  LoadingSpinner,
  ErrorMessage,
} from "./UserList.styles";

const UserList = () => {
  const { t } = useTranslation();
  const { data: users, isLoading, error } = useQuery("users", fetchUsers);

  const breadcrumb = [
    { text: t("userList", "home"), url: "/" },
    { text: t("userList", "users"), url: "/users" },
  ];

  if (isLoading)
    return <LoadingSpinner>{t("userList", "loading")}</LoadingSpinner>;
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>;

  return (
    <Layout
      title={t("userList", "User List")}
      breadcrumb={breadcrumb}
      metaDescription={t("userList", "User Management")}
    >
      <ContentContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>{t("userList", "id")}</Th>
              <Th>{t("userList", "name")}</Th>
              <Th>{t("userList", "email")}</Th>
              <Th>{t("userList", "role")}</Th>
              <Th>{t("userList", "status")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users?.map((user) => (
              <Tr key={user.id}>
                <Td>{user.id}</Td>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>{user.role}</Td>
                <Td>{user.status}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ContentContainer>
    </Layout>
  );
};

export default UserList;
