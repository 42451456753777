import React from "react";
import PropTypes from "prop-types";
import { formatPrice } from "../../lib/priceFormatter";
import { useTranslation } from "../../hooks/useTranslation";
import {
  TableCard,
  Table,
  Tr,
  SizeTh,
  SmallTh,
  ActionTh,
  SizeTd,
  SmallTd,
  ActionTd,
  AddToCartButton,
  FilterButton,
  FilterContainer,
} from "./SizeStockPriceTable.styles";

const SizeStockPriceTable = ({ data, onAddToCart }) => {
  const { t } = useTranslation();
  const [showInStock, setShowInStock] = React.useState(true);

  const filteredData = showInStock
    ? data.filter((item) => item.stock > 0)
    : data;

  const totalItems = data.length;
  const inStockItems = data.filter((size) => size.stock > 0).length;

  console.log(filteredData);

  return (
    <TableCard>
      <FilterContainer>
        <FilterButton onClick={() => setShowInStock(!showInStock)}>
          {showInStock
            ? `${t("buttons", "showAll")} (${totalItems})`
            : `${t("buttons", "showInStockOnly")} (${inStockItems})`}
        </FilterButton>
      </FilterContainer>
      <Table>
        <thead>
          <tr>
            <SmallTh>{t("productTable", "sourceProductId")}</SmallTh>
            <SizeTh>{t("productTable", "size")}</SizeTh>
            <SmallTh>{t("productTable", "stock")}</SmallTh>
            <SmallTh>{t("productTable", "buyPrice")}</SmallTh>
            <SmallTh>{t("productTable", "wholesalerPrice")}</SmallTh>
            <SmallTh>{t("productTable", "retailerPrice")}</SmallTh>
            <SmallTh>{t("productTable", "publicPrice")}</SmallTh>
            <ActionTh>{t("productTable", "action")}</ActionTh>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <Tr key={index}>
              <SmallTd>{item.sourceProductId}</SmallTd>
              <SizeTd>{item.size}</SizeTd>
              <SmallTd>{item.stock}</SmallTd>
              <SmallTd>{formatPrice(item.buyPrice)}</SmallTd>
              <SmallTd>{formatPrice(item.wholesalerPrice)}</SmallTd>
              <SmallTd>{formatPrice(item.retailerPrice)}</SmallTd>
              <SmallTd>{formatPrice(item.publicPrice)}</SmallTd>
              <ActionTd>
                <AddToCartButton
                  onClick={() => onAddToCart(item)}
                  disabled={item.stock === 0}
                >
                  {item.stock === 0
                    ? t("buttons", "outOfStock")
                    : t("buttons", "addToCart")}
                </AddToCartButton>
              </ActionTd>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableCard>
  );
};

SizeStockPriceTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      sourceProductId: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      stock: PropTypes.number.isRequired,
      buyPrice: PropTypes.number.isRequired,
      wholesalerPrice: PropTypes.number.isRequired,
      retailerPrice: PropTypes.number.isRequired,
      publicPrice: PropTypes.number.isRequired,
    })
  ).isRequired,
  onAddToCart: PropTypes.func.isRequired,
};

export default SizeStockPriceTable;
