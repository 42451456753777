import React, { useState, useEffect, useCallback, useRef } from "react";
import Layout from "../components/layout/Layout";
import StickyWrapper from "../components/layout/StickyWrapper";
import ImagePopup from "../components/ui/ImagePopup";
import SourceProductFilter from "../components/product/SourceProductFilter";
import SourceProductCard from "../components/product/SourceProductCard";
import SourceProductTable from "../components/product/SourceProductTable";
import { searchSourceProducts } from "../lib/sourceProductApi";
import { debounce } from "../lib/utils";
import { useTranslation } from "../hooks/useTranslation";
import { ContentContainer, ProductGrid } from "./SourceProductList.styles";

function SourceProductList() {
  const [sourceProducts, setSourceProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const loader = useRef(null);
  const [error, setError] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const [filters, setFilters] = useState({
    sourceProductId: null,
    provider: null,
    providerRef: null,
    category1: null,
    category2: null,
    minTotalStock: null,
    maxTotalStock: null,
    name: null,
    minPrice: null,
    maxPrice: null,
    metal: null,
    customizable: null,
  });

  const { t } = useTranslation();

  const ITEMS_PER_PAGE = 9;
  const ITEMS_TO_FETCH = ITEMS_PER_PAGE + 1;

  const [isInitialized, setIsInitialized] = useState(false);

  const [viewMode, setViewMode] = useState(() => {
    const savedViewMode = localStorage.getItem("sourceProductViewMode");
    return savedViewMode || "grid";
  });

  const fetchProducts = useCallback(async () => {
    if (!isInitialized) return; // Prevent fetching before initialization
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    setError(null);
    console.log("Fetching products for page:", page);
    try {
      const data = await searchSourceProducts({
        offset: page * ITEMS_PER_PAGE,
        limit: ITEMS_TO_FETCH,
        includeImages: true,
        includeDescriptions: true,
        includeChildren: true,
        ...Object.fromEntries(
          Object.entries(filters).map(([key, value]) => [
            key,
            value === null ? undefined : value,
          ])
        ),
      });

      if (data.length > 0) {
        const hasMoreItems = data.length === ITEMS_TO_FETCH;
        const newProducts = data.slice(0, ITEMS_PER_PAGE);
        setSourceProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setPage((prevPage) => prevPage + 1);
        setHasMore(hasMoreItems);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to load products. Please try again later.");
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [page, isLoading, hasMore, filters, isInitialized]);

  const debouncedFetchProducts = useCallback(
    debounce(() => {
      fetchProducts();
    }, 300),
    [fetchProducts]
  );

  useEffect(() => {
    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting && !isLoading && hasMore) {
        debouncedFetchProducts();
      }
    };

    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [debouncedFetchProducts, isLoading, hasMore]);

  const applyFilters = useCallback(() => {
    setSourceProducts([]);
    setPage(0);
    setHasMore(true);
    setIsInitialized(true); // Set initialization flag
    debouncedFetchProducts();
  }, [debouncedFetchProducts]);

  const handleImageClick = (images) => {
    setSelectedImages(images);
  };

  const closeImagePopup = () => {
    setSelectedImages(null);
  };

  const handleChildChange = (parentId, childId) => {
    setSourceProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.sourceProduct.sourceProductId === parentId) {
          const selectedChild = product.children.find(
            (child) => child.sourceProductId === childId
          );
          return {
            ...product,
            selectedChild: selectedChild || null,
          };
        }
        return product;
      })
    );
  };

  // Updated breadcrumb
  const breadcrumb = [
    { text: t("sourceProductList", "home"), url: "/" },
    { text: t("sourceProductList", "sourceProducts"), url: "/source-products" },
  ];

  const handleViewModeToggle = () => {
    const newViewMode = viewMode === "grid" ? "table" : "grid";
    setViewMode(newViewMode);
    localStorage.setItem("sourceProductViewMode", newViewMode);
  };

  return (
    <Layout
      title={t("sourceProductList", "sourceProducts")}
      breadcrumb={breadcrumb}
      metaDescription={t("sourceProductList", "sourceProducts")}
    >
      <StickyWrapper stickyPos={1}>
        <SourceProductFilter
          filters={filters}
          setFilters={setFilters}
          applyFilters={applyFilters}
          viewMode={viewMode}
          onViewModeToggle={handleViewModeToggle}
        />
      </StickyWrapper>
      <ContentContainer>
        {error && (
          <p style={{ color: "red", textAlign: "center" }}>
            {t("sourceProductList", "error")}
          </p>
        )}
        {sourceProducts.length === 0 && !isLoading && !error && (
          <p style={{ textAlign: "center" }}>
            {t("sourceProductList", "noProducts")}
          </p>
        )}
        {viewMode === "grid" ? (
          <ProductGrid>
            {sourceProducts.map((sourceProduct) => (
              <SourceProductCard
                key={sourceProduct.sourceProduct.sourceProductId}
                sourceProduct={sourceProduct}
                onImageClick={handleImageClick}
                onChildChange={handleChildChange}
              />
            ))}
          </ProductGrid>
        ) : (
          <SourceProductTable
            sourceProducts={sourceProducts}
            onImageClick={handleImageClick}
          />
        )}
        {isLoading && (
          <p style={{ textAlign: "center", marginTop: "2rem" }}>
            {t("sourceProductList", "loading")}
          </p>
        )}
        {!isLoading && hasMore && <div ref={loader}></div>}
        {!isLoading && !hasMore && sourceProducts.length > 0 && (
          <p
            style={{
              textAlign: "center",
              marginTop: "2rem",
              fontStyle: "italic",
            }}
          >
            {t("sourceProductList", "noMore")}
          </p>
        )}
        {selectedImages && (
          <ImagePopup images={selectedImages} onClose={closeImagePopup} />
        )}
      </ContentContainer>
    </Layout>
  );
}

export default SourceProductList;
