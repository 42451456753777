import styled from "styled-components";

export const ContentContainer = styled.div``;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
