import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useCartContext } from "../contexts/CartContext";
import Layout from "../components/layout/Layout";
import ImagePopup from "../components/ui/ImagePopup";
import InfoLine from "../components/ui/InfoLine";
import SizeStockPriceTable from "../components/product/SizeStockPriceTable";
import { getSourceProductById } from "../lib/sourceProductApi";
import { formatSourceProductInfo } from "../lib/sourceProductFormatters";
import {
  getProductName,
  getProductDescription,
  getProductImage,
} from "../lib/productUtils";
import { useTranslation } from "../hooks/useTranslation";
import { useLanguage } from "../contexts/LanguageContext";
import ShoppingCartPopup from "../components/cart/ShoppingCartPopup";
import {
  ProductImage,
  ErrorMessage,
  LoadingSpinner,
  Container,
  ContentWrapper,
  ImageColumn,
  InfoColumn,
  ProductDescription,
  AddToCartButton,
  AttributesColumn,
} from "./SourceProductDetail.styles";

// Updated getter functions
const getBreadcrumb = (productName, productId, t, categories = []) => [
  { text: t("productDetail", "home"), url: "/" },
  { text: t("productDetail", "products"), url: "/source-products" },
  { text: productName, url: `/source-product/${productId}` },
];

const getSizeStockPrice = (children) => {
  if (!children) return [];
  return children.map((item) => ({
    sourceProductId: item.sourceProductId,
    size: item.data.variationAttributes.size,
    stock: item.totalStock,
    buyPrice: item.buyPrice,
    wholesalerPrice: item.wholesalerPrice,
    retailerPrice: item.retailerPrice,
    publicPrice: item.publicPrice,
  }));
};

// Add this helper function at the top level
const formatExtraValue = (value) => {
  if (typeof value === "boolean") {
    return value ? "Oui" : "Non";
  }
  if (Array.isArray(value)) {
    return value.join(", ");
  }
  return value;
};

function ProductDetail() {
  const { id } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSimulatedLoadingComplete, setIsSimulatedLoadingComplete] =
    useState(false);
  const [isCartPopupOpen, setIsCartPopupOpen] = useState(false);
  const [addedItem, setAddedItem] = useState(null);
  const { language } = useLanguage();
  const { addQuantity } = useCartContext();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSimulatedLoadingComplete(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const {
    data: sourceProduct,
    isLoading,
    error,
  } = useQuery(["product", id], () => getSourceProductById(id), {
    staleTime: 5 * 60 * 1000,
  });

  if (isLoading || !isSimulatedLoadingComplete)
    return <LoadingSpinner>{t("productDetail", "loading")}</LoadingSpinner>;
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>;
  if (!sourceProduct)
    return <ErrorMessage>{t("productDetail", "noProduct")}</ErrorMessage>;

  const {
    sourceProduct: product,
    images,
    children,
    descriptions,
  } = sourceProduct;

  const formattedInfo = formatSourceProductInfo(
    product,
    language,
    children,
    children && children.length > 0
  );

  // Add extra data to formattedInfo with "Extra - " prefix and formatted values
  if (product.data?.attributes?.extra) {
    Object.entries(product.data.attributes.extra).forEach(([key, value]) => {
      formattedInfo[`Extra - ${key}`] = formatExtraValue(value);
    });
  }

  const productName = getProductName(descriptions, language);
  const productDescription = getProductDescription(descriptions, language);

  const handleImageClick = () => {
    if (images && images.length > 0) {
      setIsPopupOpen(true);
    }
  };

  const handleAddToCart = (item = null) => {
    const imageUrl = getProductImage(images);
    const cartItem = {
      id: product.sourceProductId,
      name: productName,
      size: item ? item.size : null,
      price: item ? parseFloat(item.price) : parseFloat(product.publicPrice),
      quantity: 1,
      image: imageUrl,
    };

    addQuantity(cartItem, 1);
    setAddedItem(cartItem);
    setIsCartPopupOpen(true);
  };

  const handleCloseCartPopup = () => {
    setIsCartPopupOpen(false);
  };

  return (
    <Layout
      title={productName}
      breadcrumb={getBreadcrumb(productName, product.sourceProductId, t)}
      metaDescription={productDescription}
    >
      <Container>
        <ContentWrapper>
          <ImageColumn>
            <ProductImage
              src={getProductImage(images)}
              alt={productName}
              onClick={handleImageClick}
            />
          </ImageColumn>
          <InfoColumn>
            {Object.entries(formattedInfo)
              .slice(0, Math.ceil(Object.entries(formattedInfo).length / 2))
              .map(([label, value]) => (
                <InfoLine key={label} label={label} value={value} />
              ))}
          </InfoColumn>
          <AttributesColumn>
            {Object.entries(formattedInfo)
              .slice(Math.ceil(Object.entries(formattedInfo).length / 2))
              .map(([label, value]) => (
                <InfoLine key={label} label={label} value={value} />
              ))}
            {(!children || children.length === 0) && (
              <AddToCartButton onClick={() => handleAddToCart()}>
                {t("productDetail", "addToCart")}
              </AddToCartButton>
            )}
          </AttributesColumn>
        </ContentWrapper>
        <ProductDescription>{productDescription}</ProductDescription>
        {children && children.length > 0 && (
          <SizeStockPriceTable
            data={getSizeStockPrice(children)}
            onAddToCart={handleAddToCart}
          />
        )}
        {isPopupOpen && (
          <ImagePopup
            images={images.map((img) => img.storeUrl)}
            onClose={() => setIsPopupOpen(false)}
          />
        )}
        {isCartPopupOpen && addedItem && (
          <ShoppingCartPopup
            onClose={handleCloseCartPopup}
            addedItem={addedItem}
          />
        )}
      </Container>
    </Layout>
  );
}

export default ProductDetail;
