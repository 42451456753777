import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { useTranslation } from "../hooks/useTranslation";
import { useAuth } from "../contexts/AuthContext";
import {
  LoginContainer,
  LoginCard,
  LogoContainer,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ErrorMessage,
} from "./LoginPage.styles";
import LanguageSelector from "../components/common/LanguageSelector";

import logo from "../assets/img/logo-black.png";

function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      await login(formData.email, formData.password);
      const from = location.state?.from?.pathname || "/source-products";
      navigate(from, { replace: true });
    } catch (err) {
      setError(t("login.error", "Invalid email or password"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout variant="login">
      <LoginContainer>
        <LoginCard>
          <LogoContainer>
            <img src={logo} alt="Logo" />
            <LanguageSelector />
          </LogoContainer>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">{t("login", "email")}</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">{t("login", "password")}</Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </FormGroup>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Button type="submit" disabled={isLoading}>
              {isLoading ? t("login", "loggingIn") : t("login", "submit")}
            </Button>
          </Form>
        </LoginCard>
      </LoginContainer>
    </Layout>
  );
}

export default LoginPage;
