import styled from "styled-components";
import { FaRegTrashCan } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const ContentContainer = styled.div`
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

export const StepCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

export const StepTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Thead = styled.thead`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Tbody = styled.tbody`
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Tr = styled.tr`
  transition: ${({ theme }) => theme.table.hover.transition};

  &:hover {
    background-color: ${({ theme }) => theme.table.hover.backgroundColor};
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 10px;
    border: 1px solid ${({ theme }) => theme.table.borders.color};
  }
`;

export const Th = styled.th`
  border: 1px solid ${({ theme }) => theme.table.borders.color};
  padding: ${({ theme }) => theme.table.borders.cellPadding};
  text-align: left;
  background-color: ${({ theme }) => theme.table.header.backgroundColor};
`;

export const Td = styled.td`
  border: 1px solid ${({ theme }) => theme.table.borders.color};
  padding: ${({ theme }) => theme.table.borders.cellPadding};

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.table.borders.color};

    &:before {
      content: attr(data-label);
      font-weight: bold;
      margin-right: 10px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const DeleteIcon = styled(FaRegTrashCan)`
  color: #dc3545;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const CenteredTd = styled(Td)`
  text-align: center;
  vertical-align: middle;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const ProductLink = styled(Link)`
  color: #0066cc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const PayButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-left: auto;
  display: block;

  &:hover {
    background-color: #45a049;
  }
`;

export const TotalText = styled.strong`
  font-size: 1.2rem;
  display: inline-block;
`;

export const VatText = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text.secondary};
  display: block;
  margin-top: 0.5rem;
`;

export const TotalContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`;
