export const getProductText = (descriptions, field, language) => {
  const description = descriptions.find(
    (desc) => desc.src === "laddd-bb" && desc.locale === language
  );
  return description
    ? description[field]
    : `${field.charAt(0).toUpperCase() + field.slice(1)} Not Available`;
};

export const getProductName = (descriptions, language) =>
  getProductText(descriptions, "name", language);

export const getProductDescription = (descriptions, language) =>
  getProductText(descriptions, "description", language);

export const getProductImage = (images) => {
  if (images && images.length > 0) {
    // Assuming the first image is the main product image
    return images[0].storeUrl || images[0].url;
  }
  return ""; // Return an empty string or a default image URL if no images are available
};
