import React from "react";
import PropTypes from "prop-types";
import { formatPrice } from "../../lib/priceFormatter";
import { formatSourceProductInfo } from "../../lib/sourceProductFormatters";
import {
  getProductName,
  getProductDescription,
  getProductImage,
} from "../../lib/productUtils";
import { useLanguage } from "../../contexts/LanguageContext";
import { useTranslation } from "../../hooks/useTranslation";
import styled from "styled-components";
import { LinkButton } from "../ui/Buttons";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: ${(props) => props.theme.spacing.md} 0;
  font-family: ${(props) => props.theme.fonts.main};
`;

const Th = styled.th`
  background-color: ${(props) => props.theme.table.header.backgroundColor};
  padding: ${(props) => props.theme.table.borders.cellPadding};
  text-align: left;
  border-bottom: 2px solid ${(props) => props.theme.table.borders.color};
  color: ${(props) => props.theme.text.primary};
`;

const Td = styled.td`
  padding: ${(props) => props.theme.table.borders.cellPadding};
  border-bottom: 1px solid ${(props) => props.theme.table.borders.color};
  color: ${(props) => props.theme.text.primary};
`;

const TableRow = styled.tr`
  &:hover {
    background-color: ${(props) => props.theme.table.hover.backgroundColor};
    transition: ${(props) => props.theme.table.hover.transition};
  }
`;

const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.table.borders.color};
  border-radius: ${(props) => props.theme.spacing.xs};
`;

const TableContainer = styled.div`
  padding: ${(props) => props.theme.spacing.md};
  overflow-x: auto;
`;

const ActionCell = styled(Td)`
  white-space: nowrap;

  ${LinkButton} {
    padding: ${(props) => props.theme.spacing.xs}
      ${(props) => props.theme.spacing.sm};
    font-size: 0.9em;
  }
`;

const Description = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    white-space: normal;
    overflow: visible;
  }
`;

const CategoryCell = styled(Td)`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    white-space: normal;
    overflow: visible;
  }
`;

function SourceProductTable({ sourceProducts, onImageClick }) {
  const { language } = useLanguage();
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <Th>{t("sourceProduct", "id")}</Th>
            <Th>{t("sourceProduct", "image")}</Th>
            <Th>{t("sourceProduct", "sourceName")}</Th>
            <Th>{t("sourceProduct", "provider")}</Th>
            <Th>{t("productFilter", "providerRef")}</Th>
            <Th>{t("productFilter", "category1")}</Th>
            <Th>{t("productFilter", "category2")}</Th>
            <Th>{t("sourceProduct", "stock")}</Th>
            <Th>{t("sourceProduct", "publicPrice")}</Th>
            <Th>{t("productTable", "action")}</Th>
          </tr>
        </thead>
        <tbody>
          {sourceProducts.map((sourceProduct) => {
            const {
              sourceProduct: product,
              images,
              descriptions,
            } = sourceProduct;
            const formattedInfo = formatSourceProductInfo(product, language);
            const productName = getProductName(descriptions, language);
            const productDescription = getProductDescription(
              descriptions,
              language
            );

            return (
              <TableRow key={product.sourceProductId}>
                <Td>{product.sourceProductId}</Td>
                <Td>
                  <ProductImage
                    src={getProductImage(images)}
                    alt={productName}
                    onClick={() =>
                      onImageClick(images?.map((img) => img.storeUrl) || [])
                    }
                  />
                </Td>
                <Td>
                  <div>{productName}</div>
                  <Description title={productDescription}>
                    {productDescription}
                  </Description>
                </Td>
                <Td>{product.provider}</Td>
                <Td>{product.providerRef}</Td>
                <CategoryCell title={product.category1}>
                  {product.category1}
                </CategoryCell>
                <CategoryCell title={product.category2}>
                  {product.category2}
                </CategoryCell>
                <Td>{product.totalStock}</Td>
                <Td>{formatPrice(product.publicPrice)}</Td>
                <ActionCell>
                  <LinkButton to={`/source-product/${product.sourceProductId}`}>
                    {t("buttons", "viewDetails")}
                  </LinkButton>
                </ActionCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
}

SourceProductTable.propTypes = {
  sourceProducts: PropTypes.arrayOf(
    PropTypes.shape({
      sourceProduct: PropTypes.shape({
        sourceProductId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        providerRef: PropTypes.string.isRequired,
        category1: PropTypes.string,
        category2: PropTypes.string,
        category3: PropTypes.string,
        totalStock: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      }).isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          storeUrl: PropTypes.string.isRequired,
        })
      ),
      descriptions: PropTypes.arrayOf(
        PropTypes.shape({
          language: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default SourceProductTable;
