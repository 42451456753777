import styled from "styled-components";
import { Link } from "react-router-dom";

export const MenuNav = styled.nav`
  margin-right: 1rem;

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: 1.5rem;
  }

  a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 1.2rem;

    &:hover {
      color: #007bff;
    }
  }

  .user-menu-container {
    position: relative;
  }

  .user-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #333;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.2rem;

    &:hover {
      color: #007bff;
    }
  }

  .user-submenu {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 1000;

    .user-email {
      padding: 5px 10px;
      border-bottom: 1px solid #eee;
      margin-bottom: 5px;
      font-size: 0.9em;
      color: #666;
    }

    button {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;
      background: none;
      border: none;
      cursor: pointer;
      color: inherit;

      &:hover {
        background: #f5f5f5;
      }

      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const CartIcon = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  position: relative;
`;

export const CartCount = styled.span`
  position: absolute;
  top: 3px;
  right: 23px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
`;
