import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("isAuthenticated") === "true";
  });
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [basicAuthCredentials, setBasicAuthCredentials] = useState(() => {
    const savedCredentials = localStorage.getItem("basicAuthCredentials");
    return savedCredentials ? JSON.parse(savedCredentials) : null;
  });

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LADDD_BB_API_URL}/api/v1/auth`,
        { email, password }
      );

      console.log(response);

      if (response.data && response.data.user) {
        setIsAuthenticated(true);
        const userData = {
          email: response.data.user.email,
          name: response.data.user.name,
          role: response.data.user.role,
        };
        setUser(userData);

        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("authToken", response.data.token);

        const basicAuthCreds = {
          username: response.data.user.basicAuthUser,
          password: response.data.user.basicAuthPassword,
        };
        setBasicAuthCredentials(basicAuthCreds);
        localStorage.setItem(
          "basicAuthCredentials",
          JSON.stringify(basicAuthCreds)
        );

        return true;
      }
      return false;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    setBasicAuthCredentials(null);
    localStorage.removeItem("basicAuthCredentials");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        basicAuthCredentials,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
