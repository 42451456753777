import React from "react";
import { Link } from "react-router-dom";
import {
  BreadcrumbNav,
  BreadcrumbList,
  BreadcrumbItem,
} from "./Breadcrumb.styles";

function Breadcrumb({ items }) {
  return (
    <BreadcrumbNav aria-label="breadcrumb">
      <BreadcrumbList>
        {items.map((item, index) => (
          <BreadcrumbItem key={index}>
            {index === items.length - 1 ? (
              <span aria-current="page">{item.text}</span>
            ) : (
              <Link to={item.url}>{item.text}</Link>
            )}
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </BreadcrumbNav>
  );
}

export default Breadcrumb;
