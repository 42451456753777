import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
`;

const Button = styled.button`
  padding: 8px 12px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Input = styled.input`
  width: 40px;
  text-align: center;
  border: none;
  font-size: 16px;
  padding: 8px 0;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const IntegerInput = ({ value, onChange, min = 0, max }) => {
  const handleChange = (newValue) => {
    if (newValue >= min && (max === undefined || newValue <= max)) {
      onChange(newValue);
    }
  };

  return (
    <Container>
      <Button onClick={() => handleChange(value - 1)} disabled={value <= min}>
        −
      </Button>
      <Input
        type="number"
        value={value}
        onChange={(e) => handleChange(parseInt(e.target.value) || min)}
        min={min}
        max={max}
      />
      <Button
        onClick={() => handleChange(value + 1)}
        disabled={max !== undefined && value >= max}
      >
        +
      </Button>
    </Container>
  );
};

export default IntegerInput;
