import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { LanguageProvider } from "./contexts/LanguageContext";
import { ThemeProvider } from "styled-components";
import { theme } from "./themes/theme.main";
import { CartProvider } from "./contexts/CartContext";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import BackToTopButton from "./components/common/BackToTopButton";

import SourceProductList from "./pages/SourceProductList";
import SourceProductDetail from "./pages/SourceProductDetail";
import CartPage from "./pages/CartPage"; // Add this import
import CheckoutPage from "./pages/CheckoutPage"; // Add this import
import LoginPage from "./pages/LoginPage"; // Add this import
import UserList from "./pages/UserList"; // Add this import

import "./App.css";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LanguageProvider>
          <QueryClientProvider client={queryClient}>
            <CartProvider>
              <Router>
                <div>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                      path="/"
                      element={<Navigate replace to="/source-products" />}
                    />
                    <Route
                      path="/source-products"
                      element={
                        <ProtectedRoute>
                          <SourceProductList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/source-product/:id"
                      element={
                        <ProtectedRoute>
                          <SourceProductDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/cart"
                      element={
                        <ProtectedRoute>
                          <CartPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/checkout"
                      element={
                        <ProtectedRoute>
                          <CheckoutPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <ProtectedRoute>
                          <UserList />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                  <BackToTopButton />
                </div>
              </Router>
            </CartProvider>
          </QueryClientProvider>
        </LanguageProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
