import React, { useState, useEffect } from "react";
import { SuccessButton } from "../ui/Buttons";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";
import { formatPrice } from "../../lib/priceFormatter";
import { useTranslation } from "../../hooks/useTranslation";
import {
  PopupOverlay,
  PopupContent,
  Title,
  ProductRecap,
  ProductImage,
  PlaceholderImage,
  ProductInfo,
  ProductName,
  ProductSize,
  ProductPrice,
  QuantityControl,
  QuantityButton,
  QuantityInput,
  ButtonContainer,
} from "./ShoppingCartPopup.styles";

const ShoppingCartPopup = ({ onClose, addedItem }) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const { cart, updateQuantity } = useCartContext();

  useEffect(() => {
    const cartItem = cart.find(
      (item) => item.id === addedItem.id && item.size === addedItem.size
    );
    setQuantity(cartItem ? cartItem.quantity : addedItem.quantity);
  }, [addedItem, cart]);

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity >= 0) {
      setQuantity(newQuantity);

      if (newQuantity === 0) {
        updateQuantity(addedItem, newQuantity);
      }
    }
  };

  const renderProductImage = () => {
    if (addedItem.image) {
      return (
        <ProductImage
          src={addedItem.image}
          alt={addedItem.name}
          onError={(e) => {
            console.error("Error loading image:", e);
            e.target.onerror = null;
            e.target.src = "path/to/fallback/image.jpg";
          }}
        />
      );
    }
    return <PlaceholderImage>{t("shoppingCart", "noImage")}</PlaceholderImage>;
  };

  const handleProceedToCart = () => {
    navigate("/cart");
  };

  return (
    <PopupOverlay>
      <PopupContent>
        <Title>{t("shoppingCart", "title")}</Title>
        <ProductRecap>
          {renderProductImage()}
          <ProductInfo>
            <ProductName>{addedItem.name}</ProductName>
            {addedItem.size && (
              <ProductSize>{`${t("productTable", "size")}: ${
                addedItem.size
              }`}</ProductSize>
            )}
            <ProductPrice>{formatPrice(addedItem.price)}</ProductPrice>
            <QuantityControl>
              <QuantityButton
                onClick={() => handleQuantityChange(quantity - 1)}
              >
                -
              </QuantityButton>
              <QuantityInput
                type="number"
                value={quantity}
                onChange={(e) => handleQuantityChange(parseInt(e.target.value))}
                min={0}
              />
              <QuantityButton
                onClick={() => handleQuantityChange(quantity + 1)}
              >
                +
              </QuantityButton>
            </QuantityControl>
          </ProductInfo>
        </ProductRecap>
        <ButtonContainer>
          <SuccessButton onClick={onClose}>
            {t("buttons", "continueShopping")}
          </SuccessButton>
          <SuccessButton onClick={handleProceedToCart}>
            {t("shoppingCart", "proceedToCart")}
          </SuccessButton>
        </ButtonContainer>
      </PopupContent>
    </PopupOverlay>
  );
};

export default ShoppingCartPopup;
