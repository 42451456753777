import styled from "styled-components";
import { SuccessButton } from "../ui/Buttons";

export const TableCard = styled.div`
  margin-top: ${(props) => props.theme.spacing.lg};
  margin-bottom: ${(props) => props.theme.spacing.lg};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: ${(props) => props.theme.spacing.md};
`;

export const Tr = styled.tr`
  &:hover {
    background-color: ${({ theme }) => theme.table.hover.backgroundColor};
  }
`;

export const Th = styled.th`
  background-color: ${({ theme }) => theme.table.header.backgroundColor};
  padding: ${({ theme }) => theme.table.borders.cellPadding};
  text-align: left;
  border: 1px solid ${({ theme }) => theme.table.borders.color};
`;

export const Td = styled.td`
  padding: ${({ theme }) => theme.table.borders.cellPadding};
  border: 1px solid ${({ theme }) => theme.table.borders.color};
`;

export const SizeTh = styled(Th)`
  width: auto;
`;

export const SizeTd = styled(Td)`
  width: auto;
`;

export const SmallTh = styled(Th)`
  width: 15%;
  text-align: right;
`;

export const SmallTd = styled(Td)`
  width: 15%;
  text-align: right;
`;

export const ActionTh = styled(Th)`
  width: 1%;
  white-space: nowrap;
`;

export const ActionTd = styled(Td)`
  width: 1%;
  white-space: nowrap;
`;

export const AddToCartButton = styled(SuccessButton)`
  padding: 5px 10px;
  font-size: 14px;
  margin: 2px 2px;
`;

export const FilterButton = styled(SuccessButton)`
  padding: 5px 10px;
  font-size: 14px;
  margin: 2px 2px;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;
