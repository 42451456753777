const API_CONFIG = {
  basicAuthUser: "4ed204b2-869b-4fac-a857-86db0c4769c2",
  basicAuthPassword: "6zFGyrXg1CUUsa1VLlIJZrnFQ4+I7BpI2oYQFaoV9S0=",
};

export const postSearchSourceProductApiUrl = `${process.env.REACT_APP_LADDD_BB_API_URL}/api/v1/source-products/search`;
export const postStripeCheckoutSessionCreateApiUrl = `${process.env.REACT_APP_LADDD_BB_API_URL}/api/v1/stripe/checkout/session/create`;
export const getStripeCheckoutSessionStatusApiUrl = `${process.env.REACT_APP_LADDD_BB_API_URL}/api/v1/stripe/checkout/session/status`;

export default API_CONFIG;
