import styled from "styled-components";

export const BreadcrumbNav = styled.nav``;

export const BreadcrumbList = styled.ol`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
`;

export const BreadcrumbItem = styled.li`
  font-size: 0.9rem;
  color: #6c757d;

  &:not(:first-child)::before {
    content: "/";
    padding: 0 5px;
    color: #6c757d;
  }

  a {
    color: #007bff;
    text-decoration: none;
  }
`;
