import styled from "styled-components";

export const ContentContainer = styled.div`
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

export const LoadingContainer = styled.div`
  position: relative;
  min-height: 400px;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  background-color: rgba(75, 75, 75, 0.9);
  z-index: 9999;
`;

export const LoadingText = styled.div`
  color: white;
  font-size: 2rem;
  font-weight: bold;
`;

export const Spinner = styled.div`
  width: 4rem;
  height: 4rem;
  color: white;
`;
