import React from "react";
import PropTypes from "prop-types";
import InfoLine from "../ui/InfoLine";
import { formatSourceProductInfo } from "../../lib/sourceProductFormatters";
import {
  getProductName,
  getProductDescription,
  getProductImage,
} from "../../lib/productUtils";
import { HoverCard } from "../ui/StyledCard";
import { useLanguage } from "../../contexts/LanguageContext";
import { useTranslation } from "../../hooks/useTranslation";
import {
  ProductImage,
  ProductDescription,
  CenteredLinkButton,
  ProductName,
  ImageContainer,
} from "./SourceProductCard.styles";

function SourceProductCard({ sourceProduct, onImageClick }) {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { sourceProduct: product, images, descriptions } = sourceProduct;
  const formattedInfo = formatSourceProductInfo(product, language);
  const productName = getProductName(descriptions, language);
  const productDescription = getProductDescription(descriptions, language);

  const handleImageClick = () => {
    if (images && images.length > 0) {
      onImageClick(images.map((img) => img.storeUrl));
    }
  };

  return (
    <HoverCard>
      <ProductName>{productName}</ProductName>
      <ImageContainer>
        <ProductImage
          src={getProductImage(images)}
          alt={productName}
          onClick={handleImageClick}
        />
      </ImageContainer>
      {Object.entries(formattedInfo).map(([label, value]) => (
        <InfoLine key={label} label={label} value={value} />
      ))}
      <ProductDescription>{productDescription}</ProductDescription>
      <CenteredLinkButton to={`/source-product/${product.sourceProductId}`}>
        {t("buttons", "viewDetails")}
      </CenteredLinkButton>
    </HoverCard>
  );
}

SourceProductCard.propTypes = {
  sourceProduct: PropTypes.shape({
    sourceProduct: PropTypes.shape({
      sourceProductId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      providerRef: PropTypes.string.isRequired,
      category1: PropTypes.string,
      category2: PropTypes.string,
      category3: PropTypes.string,
      totalStock: PropTypes.number.isRequired,
      publicPrice: PropTypes.number.isRequired,
    }).isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        storeUrl: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default SourceProductCard;
