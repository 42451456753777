import styled from "styled-components";

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
`;

export const Title = styled.h2`
  margin-top: 0;
`;

export const ProductRecap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const ProductImage = styled.img`
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
  margin: 30px;
`;

export const PlaceholderImage = styled.div`
  width: 150px;
  height: 150px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

export const ProductInfo = styled.div`
  text-align: center;
`;

export const ProductName = styled.h3`
  margin: 0 0 10px 0;
`;

export const ProductSize = styled.p`
  margin: 0 0 10px 0;
  font-size: 0.9em;
  color: #666;
`;

export const ProductPrice = styled.p`
  margin: 0 0 10px 0;
  font-weight: bold;
`;

export const QuantityControl = styled.div`
  display: flex;
  justify-content: center;
`;

export const QuantityButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
`;

export const QuantityInput = styled.input`
  width: 40px;
  text-align: center;
  margin: 0 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
