export const languageOptions = [
  {
    code: "en_US",
    label: "English",
    alternateLocales: ["en_GB", "en_IE", "en_AU", "en_CA"],
    default: true,
  },
  {
    code: "fr_FR",
    label: "Français",
    alternateLocales: ["fr_BE", "fr_CH", "fr_CA"],
  },
  { code: "de_DE", label: "Deutsch", alternateLocales: ["de_AT", "de_CH"] },
  { code: "es_ES", label: "Español", alternateLocales: ["es_AR", "es_CO"] },
  { code: "it_IT", label: "Italiano", alternateLocales: ["it_CH"] },
  { code: "pt_PT", label: "Português", alternateLocales: ["pt_BR"] },
  { code: "nl_NL", label: "Nederlands", alternateLocales: ["nl_BE"] },
];
