export const theme = {
  colors: {
    primary: "#007bff",
    secondary: "#6c757d",
    success: "#28a745",
    danger: "#dc3545",
    // Add more colors as needed
  },
  text: {
    primary: "#333333",
    secondary: "#6c757d", // Added text colors
  },
  spacing: {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
  },
  fonts: {
    main: "'Roboto', sans-serif",
    // Add more font families if needed
  },
  table: {
    hover: {
      backgroundColor: "#f5f5f5",
      transition: "background-color 0.2s ease",
    },
    borders: {
      color: "#ddd",
      cellPadding: "8px",
    },
    header: {
      backgroundColor: "#f2f2f2",
    },
  },
  // Add more theme variables (e.g., breakpoints, shadows) as needed
};
