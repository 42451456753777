import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Breadcrumb from "../common/Breadcrumb";
import Menu from "../common/Menu";

import logo from "../../assets/img/logo-black.png";

const HeaderContainer = styled.header`
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: #f8f8f8;
`;

const Logo = styled.img`
  height: 40px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  padding: 1rem 2rem;
  background: #f0f0f0;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #333;
  margin: 0;
`;

function PageHeader({ title, breadcrumb, metaDescription }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <HeaderContainer>
        <TopBar>
          <Logo src={logo} alt="Bijoux Business" />
          <RightSection>
            <Menu />
          </RightSection>
        </TopBar>
        <ContentWrapper>
          <Breadcrumb items={breadcrumb} />
          <Title>{title}</Title>
        </ContentWrapper>
      </HeaderContainer>
    </>
  );
}

export default PageHeader;
