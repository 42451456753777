import React, { createContext, useContext, useState, useCallback } from "react";

const StickyContext = createContext();

export function StickyProvider({ children }) {
  const [stickyElements, setStickyElements] = useState([]);
  const [lastStickyId, setLastStickyId] = useState(null);

  const registerSticky = useCallback((id, height, stickyPos) => {
    setStickyElements((prev) => {
      const newElements = [...prev, { id, height, stickyPos }].sort(
        (a, b) => a.stickyPos - b.stickyPos
      );
      setLastStickyId(newElements[newElements.length - 1].id);
      return newElements;
    });
    return () => {
      setStickyElements((prev) => {
        const newElements = prev.filter((el) => el.id !== id);
        setLastStickyId(newElements[newElements.length - 1]?.id || null);
        return newElements;
      });
    };
  }, []);

  const updateStickyHeight = useCallback((id, height) => {
    setStickyElements((prev) =>
      prev
        .map((el) => (el.id === id ? { ...el, height } : el))
        .sort((a, b) => a.stickyPos - b.stickyPos)
    );
  }, []);

  return (
    <StickyContext.Provider
      value={{
        stickyElements,
        lastStickyId,
        registerSticky,
        updateStickyHeight,
      }}
    >
      {children}
    </StickyContext.Provider>
  );
}

export function useSticky() {
  const context = useContext(StickyContext);
  if (!context) {
    throw new Error("useSticky must be used within a StickyProvider");
  }
  return context;
}
