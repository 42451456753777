import React from "react";
import styled from "styled-components";

const InfoLineWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: flex-start;
  background-color: #f5f5f5; // Light grey background
  padding: 10px;
  border-radius: 4px; // Optional: adds rounded corners
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 0.8rem;
  width: 150px; // Set a fixed width for all labels
  flex-shrink: 0; // Prevent the label from shrinking
  margin-right: 10px;
`;

const Value = styled.span`
  flex: 1;
  font-size: 0.8rem;
  word-break: break-word; // Allow long words to break and wrap
`;

const InfoLine = ({ label, value }) => (
  <InfoLineWrapper>
    <Label>{label}:</Label>
    <Value>{value}</Value>
  </InfoLineWrapper>
);

export default InfoLine;
