import React, { useState } from "react";
import { useTranslation } from "../hooks/useTranslation";
import Layout from "../components/layout/Layout";
import { StripeCheckoutForm } from "../components/cart/StripeCheckoutForm";
import {
  ContentContainer,
  LoadingContainer,
  LoadingOverlay,
  LoadingText,
  Spinner,
} from "./CheckoutPage.styles";

const CheckoutPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const breadcrumb = [
    { text: t("checkoutPage", "home"), url: "/" },
    { text: t("checkoutPage", "checkout"), url: "/checkout" },
  ];

  return (
    <Layout
      title={t("checkoutPage", "checkout")}
      breadcrumb={breadcrumb}
      metaDescription={t("checkoutPage", "checkout")}
    >
      <ContentContainer>
        <LoadingContainer>
          {isLoading && (
            <LoadingOverlay>
              <Spinner className="spinner-border" role="status" />
              <LoadingText>{t("checkoutPage", "redirecting")}</LoadingText>
            </LoadingOverlay>
          )}
          <StripeCheckoutForm setIsLoading={setIsLoading} />
        </LoadingContainer>
      </ContentContainer>
    </Layout>
  );
};

export default CheckoutPage;
