import { useLanguage } from "../contexts/LanguageContext";
import translations from "../translations/translations";

export const useTranslation = () => {
  const { language } = useLanguage();

  const t = (context, key, paramsOrDefault = {}, defaultValue = "") => {
    // Handle the case where third argument is a string (default value)
    if (typeof paramsOrDefault === "string") {
      defaultValue = paramsOrDefault;
      paramsOrDefault = {};
    }

    let translation =
      translations[context]?.[key]?.[language] ||
      defaultValue ||
      `${context}.${key}`;

    // Replace parameters in the translation string
    if (paramsOrDefault && typeof paramsOrDefault === "object") {
      Object.keys(paramsOrDefault).forEach((param) => {
        translation = translation.replace(
          `{{${param}}}`,
          paramsOrDefault[param]
        );
      });
    }

    return translation;
  };

  return { t };
};
