import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../hooks/useTranslation";
import { useCartContext } from "../contexts/CartContext";
import { formatPrice } from "../lib/priceFormatter";
import Layout from "../components/layout/Layout";
import IntegerInput from "../components/forms/IntegerInput";
import {
  ContentContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  DeleteIcon,
  CenteredTd,
  ImageContainer,
  ProductImage,
  ProductLink,
  PayButton,
  TotalText,
  VatText,
  TotalContainer,
} from "./CartPage.styles";

const CartPage = () => {
  const { t } = useTranslation();
  const { cart, updateQuantity, removeFromCart } = useCartContext();
  const navigate = useNavigate();

  const handleQuantityChange = (item, newQuantity) => {
    if (newQuantity >= 1) {
      updateQuantity(item, newQuantity);
    }
  };

  const calculateTotal = () => {
    const subtotal = cart.reduce(
      (total, item) => total + parseFloat(item.price) * item.quantity,
      0
    );
    return {
      subtotal,
      vat: subtotal * 0.21, // 21% VAT
      total: subtotal * 1.21,
    };
  };

  const breadcrumb = [
    { text: t("cartPage", "home"), url: "/" },
    { text: t("cartPage", "cart"), url: "/cart" },
  ];

  const handlePayment = () => {
    navigate("/checkout");
  };

  return (
    <Layout
      title={t("cartPage", "cart")}
      breadcrumb={breadcrumb}
      metaDescription={t("cartPage", "cart")}
    >
      <ContentContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>{t("cartPage", "id")}</Th>
              <Th>{t("cartPage", "image")}</Th>
              <Th>{t("cartPage", "product")}</Th>
              <Th>{t("cartPage", "size")}</Th>
              <Th>{t("cartPage", "quantity")}</Th>
              <Th>{t("cartPage", "price")}</Th>
              <Th>{t("cartPage", "total")}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {cart.map((item, index) => (
              <Tr key={index}>
                <Td data-label={t("cartPage", "id")}>{item.id}</Td>
                <Td data-label={t("cartPage", "image")}>
                  <ImageContainer>
                    <ProductImage src={item.image} alt={item.name} />
                  </ImageContainer>
                </Td>
                <Td data-label={t("cartPage", "product")}>
                  <ProductLink to={`/source-product/${item.id}`}>
                    {item.name}
                  </ProductLink>
                </Td>
                <Td data-label={t("cartPage", "size")}>{item.size}</Td>
                <Td data-label={t("cartPage", "quantity")}>
                  <IntegerInput
                    value={item.quantity}
                    onChange={(newQuantity) =>
                      handleQuantityChange(item, newQuantity)
                    }
                    min={1}
                  />
                </Td>
                <Td data-label={t("cartPage", "price")}>
                  {formatPrice(item.price)}
                </Td>
                <Td data-label={t("cartPage", "total")}>
                  {formatPrice(parseFloat(item.price) * item.quantity)}
                </Td>
                <CenteredTd>
                  <DeleteIcon
                    onClick={() => removeFromCart(item.id, item.size)}
                    title={t("cartPage", "remove")}
                    aria-label={t("cartPage", "remove")}
                  />
                </CenteredTd>
              </Tr>
            ))}
          </Tbody>
          <tfoot>
            <Tr>
              <Td colSpan="7" data-label={t("cartPage", "total")}>
                <TotalText>{t("cartPage", "subtotal")}</TotalText>
              </Td>
              <Td>
                <TotalText>{formatPrice(calculateTotal().subtotal)}</TotalText>
              </Td>
            </Tr>
          </tfoot>
        </Table>
        <TotalContainer>
          <TotalText>{formatPrice(calculateTotal().total)}</TotalText>
          <VatText>
            {t("cartPage", "includingVAT", {
              amount: formatPrice(calculateTotal().vat),
            })}
          </VatText>
        </TotalContainer>
        <PayButton onClick={handlePayment}>{t("cartPage", "pay")}</PayButton>
      </ContentContainer>
    </Layout>
  );
};

export default CartPage;
