const translations = {
  buttons: {
    viewDetails: {
      en: "View Details",
      fr: "Voir les détails",
      de: "Details anzeigen",
      es: "Ver detalles",
      it: "Visualizza dettagli",
      pt: "Ver detalhes",
      nl: "Bekijk details",
    },
    addToCart: {
      en: "Add to Cart",
      fr: "Ajouter au panier",
      de: "In den Warenkorb",
      es: "Añadir al carrito",
      it: "Aggiungi al carrello",
      pt: "Adicionar ao carrinho",
      nl: "Toevoegen aan winkelwagen",
    },
    outOfStock: {
      en: "Out of Stock",
      fr: "Rupture de stock",
      de: "Nicht auf Lager",
      es: "Agotado",
      it: "Esaurito",
      pt: "Fora de estoque",
      nl: "Niet op voorraad",
    },
    continueShopping: {
      en: "Continue Shopping",
      fr: "Continuer les Achats",
      de: "Weiter einkaufen",
      es: "Seguir Comprando",
      it: "Continua lo Shopping",
      pt: "Continuar Comprando",
      nl: "Verder Winkelen",
    },
    viewCart: {
      en: "View Cart",
      fr: "Voir le Panier",
      de: "Warenkorb anzeigen",
      es: "Ver Carrito",
      it: "Visualizza Carrello",
      pt: "Ver Carrinho",
      nl: "Winkelwagen Bekijken",
    },
    applyFilters: {
      en: "Apply Filters",
      fr: "Appliquer les filtres",
      de: "Filter anwenden",
      es: "Aplicar filtros",
      it: "Applica filtri",
      pt: "Aplicar filtros",
      nl: "Filters toepassen",
    },
    clearFilters: {
      en: "Clear Filters",
      fr: "Effacer les filtres",
      de: "Filter zurücksetzen",
      es: "Borrar filtros",
      it: "Cancella filtri",
      pt: "Limpar filtros",
      nl: "Filters wissen",
    },
    showAll: {
      en: "Show All Sizes",
      fr: "Afficher toutes les tailles",
      de: "Alle Größen anzeigen",
      es: "Mostrar todas las tallas",
      it: "Mostra tutte le taglie",
      pt: "Mostrar todos os tamanhos",
      nl: "Toon alle maten",
    },
    showInStockOnly: {
      en: "Show In Stock Only",
      fr: "Afficher les tailles en stock",
      de: "Nur verfügbare Größen",
      es: "Mostrar solo disponibles",
      it: "Mostra solo disponibili",
      pt: "Mostrar apenas disponíveis",
      nl: "Toon alleen op voorraad",
    },
    showTable: {
      en: "Show as Table",
      fr: "Afficher en tableau",
      de: "Als Tabelle anzeigen",
      es: "Mostrar como tabla",
      it: "Mostra come tabella",
      pt: "Mostrar como tabela",
      nl: "Toon als tabel",
    },
    showGrid: {
      en: "Show as Grid",
      fr: "Afficher en grille",
      de: "Als Raster anzeigen",
      es: "Mostrar como cuadrícula",
      it: "Mostra come griglia",
      pt: "Mostrar como grade",
      nl: "Toon als raster",
    },
  },
  menu: {
    home: {
      en: "Home",
      fr: "Accueil",
      de: "Startseite",
      es: "Inicio",
      it: "Home",
      pt: "Início",
      nl: "Home",
    },
    products: {
      en: "Products",
      fr: "Produits",
      de: "Produkte",
      es: "Productos",
      it: "Prodotti",
      pt: "Produtos",
      nl: "Producten",
    },
    cart: {
      en: "Cart",
      fr: "Panier",
      de: "Warenkorb",
      es: "Carrito",
      it: "Carrello",
      pt: "Carrinho",
      nl: "Winkelwagen",
    },
    login: {
      en: "Login",
      fr: "Connexion",
      de: "Anmelden",
      es: "Iniciar sesión",
      it: "Accedi",
      pt: "Entrar",
      nl: "Inloggen",
    },
    logout: {
      en: "Logout",
      fr: "Déconnexion",
      de: "Abmelden",
      es: "Cerrar sesión",
      it: "Esci",
      pt: "Sair",
      nl: "Uitloggen",
    },
  },
  productTable: {
    size: {
      en: "Size",
      fr: "Taille",
      de: "Größe",
      es: "Talla",
      it: "Taglia",
      pt: "Tamanho",
      nl: "Maat",
    },
    stock: {
      en: "Stock",
      fr: "Stock",
      de: "Bestand",
      es: "Existencias",
      it: "Disponibilità",
      pt: "Estoque",
      nl: "Voorraad",
    },
    buyPrice: {
      en: "Buy Price (excl. VAT)",
      fr: "Prix d'achat (HT)",
      de: "Einkaufspreis (zzgl. MwSt.)",
      es: "Precio de compra (sin IVA)",
      it: "Prezzo d'acquisto (IVA escl.)",
      pt: "Preço de compra (sem IVA)",
      nl: "Inkoopprijs (excl. BTW)",
    },
    wholesalerPrice: {
      en: "Wholesaler Price (excl. VAT)",
      fr: "Prix grossiste (HT)",
      de: "Großhandelspreis (zzgl. MwSt.)",
      es: "Precio mayorista (sin IVA)",
      it: "Prezzo all'ingrosso (IVA escl.)",
      pt: "Preço atacado (sem IVA)",
      nl: "Groothandelsprijs (excl. BTW)",
    },
    retailerPrice: {
      en: "Retailer Price (excl. VAT)",
      fr: "Prix détaillant (HT)",
      de: "Einzelhandelspreis (zzgl. MwSt.)",
      es: "Precio minorista (sin IVA)",
      it: "Prezzo al dettaglio (IVA escl.)",
      pt: "Preço varejo (sem IVA)",
      nl: "Detailhandelsprijs (excl. BTW)",
    },
    publicPrice: {
      en: "Public Price (excl. VAT)",
      fr: "Prix public (HT)",
      de: "Verkaufspreis (zzgl. MwSt.)",
      es: "Precio público (sin IVA)",
      it: "Prezzo pubblico (IVA escl.)",
      pt: "Preço público (sem IVA)",
      nl: "Publieke prijs (excl. BTW)",
    },
    action: {
      en: "Action",
      fr: "Action",
      de: "Aktion",
      es: "Acción",
      it: "Azione",
      pt: "Ação",
      nl: "Actie",
    },
  },
  shoppingCart: {
    title: {
      en: "Added to Cart",
      fr: "Ajouté au Panier",
      de: "Zum Warenkorb hinzugefügt",
      es: "Añadido al Carrito",
      it: "Aggiunto al Carrello",
      pt: "Adicionado ao Carrinho",
      nl: "Toegevoegd aan Winkelwagen",
    },
    noImage: {
      en: "No Image",
      fr: "Pas d'Image",
      de: "Kein Bild",
      es: "Sin Imagen",
      it: "Nessuna Immagine",
      pt: "Sem Imagem",
      nl: "Geen Afbeelding",
    },
    proceedToCart: {
      en: "View cart",
      fr: "Voir le panier",
      de: "Warenkorb anzeigen",
      es: "Ver carrito",
      it: "Visualizza carrello",
      pt: "Ver carrinho",
      nl: "Winkelwagen bekijken",
    },
  },
  productFilter: {
    sourceProductId: {
      en: "Source Product ID",
      fr: "ID du produit source",
      de: "Quellprodukt-ID",
      es: "ID de producto fuente",
      it: "ID prodotto sorgente",
      pt: "ID do produto fonte",
      nl: "Bronproduct-ID",
    },
    providers: {
      en: "Providers",
      fr: "Fournisseurs",
      de: "Anbieter",
      es: "Proveedores",
      it: "Fornitori",
      pt: "Fornecedores",
      nl: "Aanbieders",
    },
    providerRef: {
      en: "Provider Ref",
      fr: "Réf. fournisseur",
      de: "Anbieterreferenz",
      es: "Ref. de proveedor",
      it: "Rif. fornitore",
      pt: "Ref. do fornecedor",
      nl: "Aanbiederreferentie",
    },
    name: {
      en: "Name of the provider",
      fr: "Nom de la source",
      de: "Name der Quelle",
      es: "Nombre de la fuente",
      it: "Nome della fonte",
      pt: "Nome da fonte",
      nl: "Naam van de bron",
    },
    category1: {
      en: "Category 1",
      fr: "Catégorie 1",
      de: "Kategorie 1",
      es: "Categoría 1",
      it: "Categoria 1",
      pt: "Categoria 1",
      nl: "Categorie 1",
    },
    category2: {
      en: "Category 2",
      fr: "Catégorie 2",
      de: "Kategorie 2",
      es: "Categoría 2",
      it: "Categoria 2",
      pt: "Categoria 2",
      nl: "Categorie 2",
    },
    minTotalStock: {
      en: "Min Total Stock",
      fr: "Stock total min",
      de: "Min. Gesamtbestand",
      es: "Stock total mínimo",
      it: "Stock totale minimo",
      pt: "Estoque total mínimo",
      nl: "Min. totale voorraad",
    },
    maxTotalStock: {
      en: "Max Total Stock",
      fr: "Stock total max",
      de: "Max. Gesamtbestand",
      es: "Stock total máximo",
      it: "Stock totale massimo",
      pt: "Estoque total máximo",
      nl: "Max. totale voorraad",
    },
    audiences: {
      en: "Audiences",
      fr: "Publics",
      de: "Zielgruppen",
      es: "Públicos",
      it: "Pubblico",
      pt: "Públicos",
      nl: "Doelgroepen",
    },
    homme: {
      en: "Men",
      fr: "Homme",
      de: "Männer",
      es: "Hombres",
      it: "Uomini",
      pt: "Homens",
      nl: "Mannen",
    },
    femme: {
      en: "Women",
      fr: "Femmes",
      de: "Frauen",
      es: "Mujeres",
      it: "Donne",
      pt: "Mulheres",
      nl: "Vrouwen",
    },
    enfant: {
      en: "Children",
      fr: "Enfants",
      de: "Kinder",
      es: "Niños",
      it: "Bambini",
      pt: "Crianças",
      nl: "Kinderen",
    },
    bébé: {
      en: "Baby",
      fr: "Bébé",
      de: "Baby",
      es: "Bebé",
      it: "Bambino",
      pt: "Bebê",
      nl: "Baby",
    },
  },
  sourceProductList: {
    home: {
      en: "Home",
      fr: "Accueil",
      de: "Startseite",
      es: "Inicio",
      it: "Home",
      pt: "Início",
      nl: "Home",
    },
    sourceProducts: {
      en: "Products",
      fr: "Produits",
      de: "Produkte",
      es: "Productos",
      it: "Prodotti",
      pt: "Produtos",
      nl: "Producten",
    },
    loading: {
      en: "Loading more products...",
      fr: "Chargement de plus de produits ...",
      de: "Lade weitere Produkte...",
      es: "Cargando más productos...",
      it: "Caricamento di altri prodotti...",
      pt: "Carregando mais produtos...",
      nl: "Meer producten laden...",
    },
    noMore: {
      en: "You've reached the end of the product list. No more products to load.",
      fr: "Vous avez atteint la fin de la liste des produits. Plus de produits à charger.",
      de: "Sie haben das Ende der Produktliste erreicht. Keine weiteren Produkte zum Laden.",
      es: "Ha llegado al final de la lista de productos. No hay más productos para cargar.",
      it: "Hai raggiunto la fine dell'elenco dei prodotti. Non ci sono più prodotti da caricare.",
      pt: "Você chegou ao fim da lista de produtos. Não há mais produtos para carregar.",
      nl: "Je hebt het einde van de productlijst bereikt. Geen producten meer om te laden.",
    },
    noProducts: {
      en: "No products available.",
      fr: "Aucun produit disponible.",
      de: "Keine Produkte verfügbar.",
      es: "No hay productos disponibles.",
      it: "Nessun prodotto disponibile.",
      pt: "Nenhum produto disponível.",
      nl: "Geen producten beschikbaar.",
    },
    error: {
      en: "Failed to load products. Please try again later.",
      fr: "Échec du chargement des produits. Veuillez réessayer plus tard.",
      de: "Fehler beim Laden der Produkte. Bitte versuchen Sie es später erneut.",
      es: "Error al cargar los productos. Por favor, inténtelo de nuevo más tarde.",
      it: "Impossibile caricare i prodotti. Si prega di riprovare più tardi.",
      pt: "Falha ao carregar os produtos. Por favor, tente novamente mais tarde.",
      nl: "Kan producten niet laden. Probeer het later opnieuw.",
    },
  },
  checkoutPage: {
    home: {
      en: "Home",
      fr: "Accueil",
      de: "Startseite",
      es: "Inicio",
      it: "Home",
      pt: "Início",
      nl: "Home",
    },
    checkout: {
      en: "Checkout",
      fr: "Paiement",
      de: "Zur Kasse",
      es: "Pago",
      it: "Pagamento",
      pt: "Finalizar Compra",
      nl: "Afrekenen",
    },
    redirecting: {
      en: "Redirecting to payment...",
      fr: "Redirection vers le paiement...",
      de: "Weiterleitung zur Zahlung...",
      es: "Redirigiendo al pago...",
      it: "Reindirizzamento al pagamento...",
      pt: "Redirecionando para o pagamento...",
      nl: "Doorsturen naar betaling...",
    },
  },
  productDetail: {
    home: {
      en: "Home",
      fr: "Accueil",
      de: "Startseite",
      es: "Inicio",
      it: "Home",
      pt: "Início",
      nl: "Home",
    },
    products: {
      en: "Products",
      fr: "Produits",
      de: "Produkte",
      es: "Productos",
      it: "Prodotti",
      pt: "Produtos",
      nl: "Producten",
    },
    loading: {
      en: "Loading...",
      fr: "Chargement...",
      de: "Wird geladen...",
      es: "Cargando...",
      it: "Caricamento...",
      pt: "Carregando...",
      nl: "Laden...",
    },
    noProduct: {
      en: "No product found",
      fr: "Aucun produit trouvé",
      de: "Kein Produkt gefunden",
      es: "No se encontró ningún producto",
      it: "Nessun prodotto trovato",
      pt: "Nenhum produto encontrado",
      nl: "Geen product gevonden",
    },
    addToCart: {
      en: "Add to Cart",
      fr: "Ajouter au Panier",
      de: "In den Warenkorb",
      es: "Añadir al Carrito",
      it: "Aggiungi al Carrello",
      pt: "Adicionar ao Carrinho",
      nl: "Toevoegen aan Winkelwagen",
    },
  },
  cartPage: {
    home: {
      en: "Home",
      fr: "Accueil",
      de: "Startseite",
      es: "Inicio",
      it: "Home",
      pt: "Início",
      nl: "Home",
    },
    cart: {
      en: "Your shopping cart",
      fr: "Votre panier",
      de: "Ihr Warenkorb",
      es: "Su carrito de compras",
      it: "Il tuo carrello",
      pt: "Seu carrinho de compras",
      nl: "Uw winkelwagen",
    },
    cartTitle: {
      en: "Your shopping cart",
      fr: "Votre panier",
      de: "Ihr Warenkorb",
      es: "Su carrito de compras",
      it: "Il tuo carrello",
      pt: "Seu carrinho de compras",
      nl: "Uw winkelwagen",
    },
    id: {
      en: "#",
      fr: "#",
      de: "#",
      es: "#",
      it: "#",
      pt: "#",
      nl: "#",
    },
    product: {
      en: "Product",
      fr: "Produit",
      de: "Produkt",
      es: "Producto",
      it: "Prodotto",
      pt: "Produto",
      nl: "Product",
    },
    size: {
      en: "Size",
      fr: "Taille",
      de: "Größe",
      es: "Talla",
      it: "Taglia",
      pt: "Tamanho",
      nl: "Maat",
    },
    quantity: {
      en: "Quantity",
      fr: "Quantité",
      de: "Menge",
      es: "Cantidad",
      it: "Quantità",
      pt: "Quantidade",
      nl: "Aantal",
    },
    price: {
      en: "Price (excl. VAT)",
      fr: "Prix (HT)",
      de: "Preis (zzgl. MwSt.)",
      es: "Precio (sin IVA)",
      it: "Prezzo (IVA escl.)",
      pt: "Preço (sem IVA)",
      nl: "Prijs (excl. BTW)",
    },
    remove: {
      en: "Remove item",
      fr: "Supprimer l'article",
      de: "Artikel entfernen",
      es: "Eliminar artículo",
      it: "Rimuovi elemento",
      pt: "Remover item",
      nl: "Item verwijderen",
    },
    pay: {
      en: "Pay",
      fr: "Payer",
      de: "Bezahlen",
      es: "Pagar",
      it: "Paga",
      pt: "Pagar",
      nl: "Betalen",
    },
    image: {
      en: "Image",
      fr: "Image",
      de: "Bild",
      es: "Imagen",
      it: "Immagine",
      pt: "Imagem",
      nl: "Afbeelding",
    },
    subtotal: {
      en: "Subtotal (excl. VAT)",
      fr: "Sous-total (HT)",
      de: "Zwischensumme (zzgl. MwSt.)",
      es: "Subtotal (sin IVA)",
      it: "Subtotale (IVA escl.)",
      pt: "Subtotal (sem IVA)",
      nl: "Subtotaal (excl. BTW)",
    },
    includingVAT: {
      en: "Including VAT: {{amount}}",
      fr: "TVA incluse : {{amount}}",
      de: "Inkl. MwSt: {{amount}}",
      es: "IVA incluido: {{amount}}",
      it: "IVA inclusa: {{amount}}",
      pt: "IVA incluído: {{amount}}",
      nl: "Incl. BTW: {{amount}}",
    },
    total: {
      en: "Total (excl. VAT)",
      fr: "Total (HT)",
      de: "Gesamt (zzgl. MwSt.)",
      es: "Total (sin IVA)",
      it: "Totale (IVA escl.)",
      pt: "Total (sem IVA)",
      nl: "Totaal (excl. BTW)",
    },
  },
  login: {
    title: {
      en: "Login",
      fr: "Connexion",
      de: "Anmelden",
      es: "Iniciar sesión",
      it: "Accedi",
      pt: "Entrar",
      nl: "Inloggen",
    },
    email: {
      en: "Email",
      fr: "Email",
      de: "E-Mail",
      es: "Correo electrónico",
      it: "Email",
      pt: "Email",
      nl: "E-mail",
    },
    password: {
      en: "Password",
      fr: "Mot de passe",
      de: "Passwort",
      es: "Contraseña",
      it: "Password",
      pt: "Senha",
      nl: "Wachtwoord",
    },
    submit: {
      en: "Login",
      fr: "Se connecter",
      de: "Anmelden",
      es: "Iniciar sesión",
      it: "Accedi",
      pt: "Entrar",
      nl: "Inloggen",
    },
    error: {
      en: "Invalid email or password",
      fr: "Email ou mot de passe invalide",
      de: "Ungültige E-Mail oder Passwort",
      es: "Correo o contraseña inválidos",
      it: "Email o password non validi",
      pt: "Email ou senha inválidos",
      nl: "Ongeldige email of wachtwoord",
    },
    loggingIn: {
      en: "Logging in...",
      fr: "Connexion en cours...",
      de: "Anmeldung läuft...",
      es: "Iniciando sesión...",
      it: "Accesso in corso...",
      pt: "Entrando...",
      nl: "Inloggen...",
    },
    metaDescription: {
      en: "Login to your account",
      fr: "Connectez-vous à votre compte",
      de: "Melden Sie sich bei Ihrem Konto an",
      es: "Inicie sesión en su cuenta",
      it: "Accedi al tuo account",
      pt: "Entre na sua conta",
      nl: "Log in op uw account",
    },
    home: {
      en: "Home",
      fr: "Accueil",
      de: "Startseite",
      es: "Inicio",
      it: "Home",
      pt: "Início",
      nl: "Home",
    },
    login: {
      en: "Login",
      fr: "Connexion",
      de: "Anmelden",
      es: "Iniciar sesión",
      it: "Accedi",
      pt: "Entrar",
      nl: "Inloggen",
    },
  },
  sourceProduct: {
    id: {
      en: "Id",
      fr: "Id",
      de: "Id",
      es: "Id",
      it: "Id",
      pt: "Id",
      nl: "Id",
    },
    sourceName: {
      en: "Source Name",
      fr: "Nom de la source",
      de: "Quellenname",
      es: "Nombre de origen",
      it: "Nome fonte",
      pt: "Nome da fonte",
      nl: "Bronnaam",
    },
    provider: {
      en: "Provider",
      fr: "Fournisseur",
      de: "Anbieter",
      es: "Proveedor",
      it: "Fornitore",
      pt: "Fornecedor",
      nl: "Leverancier",
    },
    categories: {
      en: "Categories",
      fr: "Catégories",
      de: "Kategorien",
      es: "Categorías",
      it: "Categorie",
      pt: "Categorias",
      nl: "Categorieën",
    },
    stock: {
      en: "Stock (all sizes)",
      fr: "Stock (toutes tailles)",
      de: "Bestand (alle Größen)",
      es: "Stock (todas las tallas)",
      it: "Stock (tutte le taglie)",
      pt: "Estoque (todos os tamanhos)",
      nl: "Voorraad (alle maten)",
    },
    publicPrice: {
      en: "Public price",
      fr: "Prix public",
      de: "Öffentlicher Preis",
      es: "Precio público",
      it: "Prezzo al pubblico",
      pt: "Preço público",
      nl: "Publieke prijs",
    },
    uncategorized: {
      en: "Uncategorized",
      fr: "Non catégorisé",
      de: "Nicht kategorisiert",
      es: "Sin categorizar",
      it: "Non categorizzato",
      pt: "Não categorizado",
      nl: "Niet gecategoriseerd",
    },
    mainWarehouse: {
      en: "Main Warehouse Stock",
      fr: "Stock Entrepôt Principal",
      de: "Hauptlagerbestand",
      es: "Stock Almacén Principal",
      it: "Stock Magazzino Principale",
      pt: "Estoque Armazém Principal",
      nl: "Hoofdmagazijnvoorraad",
    },
    customizable: {
      en: "Customizable",
      fr: "Personnalisable",
      de: "Anpassbar",
      es: "Personalizable",
      it: "Personalizzabile",
      pt: "Personalizável",
      nl: "Aanpasbaar",
    },
    buyPrice: {
      en: "Buy Price",
      fr: "Prix d'Achat",
      de: "Einkaufspreis",
      es: "Precio de Compra",
      it: "Prezzo d'Acquisto",
      pt: "Preço de Compra",
      nl: "Inkoopprijs",
    },
    wholesalerPrice: {
      en: "Wholesaler Price",
      fr: "Prix Grossiste",
      de: "Großhandelspreis",
      es: "Precio Mayorista",
      it: "Prezzo all'Ingrosso",
      pt: "Preço Atacado",
      nl: "Groothandelsprijs",
    },
    retailerPrice: {
      en: "Retailer Price",
      fr: "Prix Détaillant",
      de: "Einzelhandelspreis",
      es: "Precio Minorista",
      it: "Prezzo al Dettaglio",
      pt: "Preço Varejo",
      nl: "Detailhandelsprijs",
    },
    yes: {
      en: "Yes",
      fr: "Oui",
      de: "Ja",
      es: "Sí",
      it: "Sì",
      pt: "Sim",
      nl: "Ja",
    },
    no: {
      en: "No",
      fr: "Non",
      de: "Nein",
      es: "No",
      it: "No",
      pt: "Não",
      nl: "Nee",
    },
    notAvailable: {
      en: "N/A",
      fr: "N/D",
      de: "N/V",
      es: "N/D",
      it: "N/D",
      pt: "N/D",
      nl: "N/B",
    },
  },
};

export default translations;
