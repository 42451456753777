import axios from "axios";
import API_CONFIG, { postSearchSourceProductApiUrl } from "../config/apiConfig";

/**
 * @api {post} /source-products/search Search source products
 * @apiName SearchSourceProducts
 * @apiGroup SourceProduct
 * @apiDescription Full API documentation available at: /docs/sourceProductApi.yaml
 */
const searchSourceProducts = async (params) => {
  try {
    const response = await axios.post(postSearchSourceProductApiUrl, params, {
      auth: {
        username: API_CONFIG.basicAuthUser,
        password: API_CONFIG.basicAuthPassword,
      },
    });
    return response.data.sourceProductList;
  } catch (error) {
    console.error("Error searching source products:", error);
    throw error;
  }
};

const getSourceProductById = async (id) => {
  try {
    const response = await searchSourceProducts({
      limit: 1,
      sourceProductIds: [id],
      includeImages: true,
      includeDescriptions: true,
      includeChildren: true,
    });

    return response ? response[0] : null;
  } catch (error) {
    console.error(`Error fetching product with id ${id}:`, error);
    throw error;
  }
};

export { searchSourceProducts, getSourceProductById };
